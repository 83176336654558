import { formatTimestampByPattern, IMAGES_TYPE } from '@app/utils';
import { inject, observer } from 'mobx-react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { uniqBy } from 'lodash-es';

export const InterceptEventDetailed = inject(({ store }) => ({
  isLoading: store.eventsInterceptions.isLoading,
  current: store.eventsInterceptions.current ?? {},
  setShowDrawer: store.ui.setShowDrawer,
  setImageModalInfo: store.eventsInterceptions.setImageModalInfo,
  reasons: store.wantedInterceptions.reasonsList ?? [],
}))(
  observer(
    ({ current, isLoading, setShowDrawer, setImageModalInfo, reasons }) => {
      const { t } = useTranslation(['inputs', 'buttons']);

      const commentsList = current.event?.comment
        ? current.event?.comment?.split(';') || [current.event?.comment]
        : [];

      const openInModal = (image) => {
        if (image.type !== IMAGES_TYPE.PLATE) {
          setImageModalInfo(image);
          setShowDrawer('imageModal', true);
        }
      };

      return (
        <div
          className={`max-w-full p-4 overflow-y-auto  text-primary dark:text-white`}>
          <div className={'py-4 border-b border-b-line'}>
            <p className={'font-medium text-h1'}>
              {current.event?.plate_number} {isLoading ? 'Загрузка...' : ''}
            </p>
          </div>
          {current.event?.plate_number && (
            <Fragment>
              <div className={'flex flex-col flex-wrap'}>
                {uniqBy(
                  [...(current.files || []), ...(current.fast_files || [])],
                  'url',
                )?.map((f) => {
                  return (
                    <button className="contents" onClick={() => openInModal(f)}>
                      <img
                        key={f.url}
                        className={`max-h-[300px] mb-1 ${
                          f?.type === IMAGES_TYPE.PLATE && 'w-full h-auto'
                        }`}
                        src={f?.url}
                        alt={f?.type}
                      />
                    </button>
                  );
                })}
              </div>
              <div className={'py-4 border-b border-b-line'}>
                <span className={'gradient-accent text-p3 font-bold uppercase'}>
                  {t('description')}
                </span>
                <p>{current.event?.description}</p>
              </div>
              <div className={'py-4 border-b border-b-line'}>
                <span className={'gradient-accent text-p3 font-bold uppercase'}>
                  {t('controlClassifier')}
                </span>
                <p>{reasons[current.event?.reason] ?? '???'}</p>
              </div>
              <div className={'py-4 border-b border-b-line'}>
                <span className={'gradient-accent text-p3 font-bold uppercase'}>
                  {t('time')}
                </span>
                <p>
                  {formatTimestampByPattern({
                    timestamp: current.event?.datetime,
                    pattern: 'DD-MM-YYYY HH:mm:ss',
                  })}
                </p>
              </div>

              {current.event?.comment && (
                <div>
                  <span
                    className={'gradient-accent text-p3 font-bold uppercase'}>
                    {t('comment')}
                  </span>
                  <p className={'flex flex-col '}>
                    {commentsList.map((item, i) => (
                      <span key={i} className={'my-2'}>
                        {item}
                      </span>
                    ))}
                  </p>
                </div>
              )}
              <div className={'py-4 border-b border-b-line'}>
                <span className={'gradient-accent text-p3 font-bold uppercase'}>
                  {t('address')}
                </span>
                <p>{current.camera?.data?.description ?? ''}</p>
              </div>
              {current.wanted?.id && (
                <div className={'py-4 border-b border-b-line'}>
                  <a
                    target={'_blank'}
                    href={`#/wanted/${current.wanted?.id}/edit`}
                    className={
                      'gradient-accent underline text-p3 font-bold uppercase'
                    }>
                    {t('orientation', { ns: 'buttons' })}
                  </a>
                </div>
              )}
            </Fragment>
          )}
        </div>
      );
    },
  ),
);
